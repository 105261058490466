import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const CpbStandaloneMedia = ({theme, media, displayCaption}) => {
  theme = theme.toLowerCase().replace(" ", "-")
  return (
    <div className={`u__spacing u__vspacing theme-${theme}`}>
      <div className={`u__container u__content-plus cpb-standalone-media`}>
        {media.file.contentType === "image/jpeg" &&
          <figure>
            <GatsbyImage
              image={media.gatsbyImageData}
              alt={displayCaption ? "" : media.description}
            />
            {displayCaption &&
              <figcaption>{media.description}</figcaption>
            }
          </figure>
        }
        {media.file.contentType === "video/mp4" &&
          <video controls width="100%">
            <source src={media.file.url} type="video/mp4" />
          </video>
        }
      </div>
    </div>
  )
}

export default CpbStandaloneMedia

export const modelName = "ContentfulCpbStandaloneMedia"

export const componentQuery = graphql`
  fragment CpbStandaloneMediaFragment on ContentfulCpbStandaloneMedia {
    __typename
    contentful_id
    theme
    media {
      description
      gatsbyImageData(width: 1000)
      file {
        contentType
        url
      }
    }
    displayCaption
  }
`
