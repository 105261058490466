import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const CpbTextImage = ({theme, layout, ratio, image, displayCaption, childContentfulCpbTextImageTextTextNode}) => {
  theme = theme.toLowerCase().replace(" ", "-")
  ratio = ratio.replace("/", "-")
  const text = childContentfulCpbTextImageTextTextNode.childMarkdownRemark.html
  let bsFirstCol = ""
  let bsSecondCol = ""
  if (ratio === "30-70" || (ratio === "70-30" && !layout)) {
    bsFirstCol = "col-sm-4"
    bsSecondCol = "col-sm-8"
  } else if (ratio === "70-30" || (ratio === "30-70" && !layout)) {
    bsFirstCol = "col-sm-8"
    bsSecondCol = "col-sm-4"
  } else {
    bsFirstCol = bsSecondCol = "col-sm-6"
  }
  return (
    <div className={`u__spacing u__vspacing theme-${theme}`}>
      <div className={`u__container u__content-plus cpb-text-image row no-gutters align-items-center${layout ? "" : " flex-row-reverse"}`}>
        <div className={`cpb-text-image__image col-12 ${bsFirstCol}${layout ? "" : " reversed"}`}>
          <figure>
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={displayCaption ? "" : image.description}
            />
            {displayCaption &&
              <figcaption>{image.description}</figcaption>
            }
          </figure>
        </div>
        <div className={`cpb-text-image__text text-left col-12 ${bsSecondCol}${layout ? "" : " reversed"}`} dangerouslySetInnerHTML={{__html: text}}></div>
      </div>
    </div>
  )
}

export default CpbTextImage

export const modelName = "ContentfulCpbTextImage"

export const componentQuery = graphql`
  fragment CpbTextImageFragment on ContentfulCpbTextImage {
    __typename
    contentful_id
    theme
    layout
    ratio
    image {
      gatsbyImageData(width: 600)
      description
    }
    displayCaption
    childContentfulCpbTextImageTextTextNode {
      childMarkdownRemark {
        html
      }
    }
  }
`
