import React from "react"
import { graphql } from "gatsby"

const CpbBody = ({theme, body}) => {
  theme = theme.toLowerCase().replace(" ", "-")
  return (
    <div className={`u__spacing u__vspacing theme-${theme}`}>
      <div
        className="u__container u__content cpb-body"
        dangerouslySetInnerHTML={{__html: body.childMarkdownRemark.html}}
      >
      </div>
    </div>
  )
}

export default CpbBody

export const modelName = "ContentfulCpbBody"

export const componentQuery = graphql`
  fragment CpbBodyFragment on ContentfulCpbBody {
    __typename
    contentful_id
    theme
    body {
      childMarkdownRemark {
        html
      }
    }
  }
`
