import React from "react"
import { graphql } from "gatsby"

const CpbQuote = ({theme, body, attribution}) => {
  theme = theme.toLowerCase().replace(" ", "-")
  return (
    <div className={`u__spacing u__vspacing theme-${theme}`}>
      <div className="u__container u__content-plus cpb-quote">
        <figure>
          <blockquote className="cpb-quote__body eagle">{body.body}</blockquote>
          <figcaption className="cpb-quote__attribution">{attribution}</figcaption>
        </figure>
      </div>
    </div>
  )
}

export default CpbQuote

export const modelName = "ContentfulCpbQuote"

export const componentQuery = graphql`
  fragment CpbQuoteFragment on ContentfulCpbQuote {
    __typename
    contentful_id
    theme
    body {
      body
    }
    attribution
  }
`
