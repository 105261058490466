import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/Seo"
import Layout from "../components/layout/Layout"
import Cityscape from "../components/Cityscape"
import CpbBody, { modelName as CpbBodyModelName, } from "../components/CpbBody"
import CpbBody3Col, { modelName as CpbBody3ColModelName, } from "../components/CpbBody3Col"
import CpbExternalVideo, { modelName as CpbExternalVideoModelName, } from "../components/CpbExternalVideo"
import CpbQuote, { modelName as CpbQuoteModelName, } from "../components/CpbQuote"
import CpbStandaloneMedia, { modelName as CpbStandaloneMediaModelName, } from "../components/CpbStandaloneMedia"
import CpbTextImage, { modelName as CpbTextImageModelName, } from "../components/CpbTextImage"
import GetUpdates from "../components/GetUpdates"

const CustomPageTemplate = ({ data }) => {
  const {
    featuredImage,
    title,
    blocks,
  } = data.contentfulCustomPage
  const hero = getImage(featuredImage)

  const content = () => {
    if (blocks) {
      return (blocks.map((block) => {
        switch (block.__typename) {
          case CpbBodyModelName:
            return (
              <CpbBody {...block} key={block.contentful_id} />
            )
          case CpbBody3ColModelName:
            return (
              <CpbBody3Col {...block} key={block.contentful_id} />
            )
          case CpbExternalVideoModelName:
            return (
              <CpbExternalVideo {...block} key={block.contentful_id} />
            )
          case CpbQuoteModelName:
            return (
              <CpbQuote {...block} key={block.contentful_id} />
            )
          case CpbStandaloneMediaModelName:
            return (
              <CpbStandaloneMedia {...block} key={block.contentful_id} />
            )
          case CpbTextImageModelName:
            return (
              <CpbTextImage {...block} key={block.contentful_id} />
            )
          default:
            return null
        }
      }))
    }
  }

  return (
    <>
      <Seo title={title} bodyClass="layout__custom-page presentation" />
      <Layout>
        <section id="content" className="layout__main s__white custom-page">
          <div className="custom-page__content">
            {featuredImage ? (
              <div className="hero__wrapper">
                <GatsbyImage
                  image={hero}
                  alt=""
                  className="hero__img"
                />
              </div>
            ) : (
              <Cityscape sky={true} splatter={false} />
            )}
            <header className="custom-page__content__header u__spacing u__vspacing">
              <div className="row u__container u__content">
                <div className="custom-page__content__title-wrapper">
                  <h1 className="custom-page__content__title display-5 mb-0">{title}</h1>
                </div>
              </div>
            </header>
            <article className="custom-page__content__body">
              {content()}
            </article>
          </div>
        </section>
        <GetUpdates />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query CustomPageQuery($slug: String!) {
    contentfulCustomPage(slug: { eq: $slug }) {
      featuredImage {
        ... on ContentfulAsset {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      title
      slug
      blocks {
        ...CpbBodyFragment
        ...CpbBody3ColFragment
        ...CpbExternalVideoFragment
        ...CpbQuoteFragment
        ...CpbStandaloneMediaFragment
        ...CpbTextImageFragment
      }
    }
  }
`

export default CustomPageTemplate
