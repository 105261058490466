import React from "react"
import Mailchimp from "./Mailchimp"

export default function GetUpdates({ sectionClass = "s__primary" }) {
  return (
    <section id="get-updates" className={`u__vspacing target-cynosure ${sectionClass}`}>
      <div className="u__container u__spacing connect">
        <div className="row">
          <div className="col-md-3 col-lg-4">
            <h2 className="h1">Get Updates</h2>
          </div>
          <div className="col-md-9 col-lg-8">
            <p className="lead">Sign up to get updates on projects, events, and new episodes of our podcast, <a href="https://thirtythousandleagues.com/" target="_blank" rel="nofollow noreferrer">30,000 Leagues</a></p>
            <Mailchimp id="main" />
          </div>
        </div>
      </div>
    </section>
  )
}
