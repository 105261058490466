import React from "react"
import { graphql } from "gatsby"

const CpbBody3Col = ({theme, column1, column2, column3}) => {
  theme = theme.toLowerCase().replace(" ", "-")
  return (
    <div className={`u__spacing u__vspacing theme-${theme}`}>
      <div className="u__container u__content-plus row justify-content-between text-center cpb-body-3-col">
        <div className="col-xs-4 col-lg-3" dangerouslySetInnerHTML={{__html: column1.childMarkdownRemark.html}}></div>
        <div className="col-xs-4 col-lg-3 pt-4 pb-4 pb-xs-0 pt-xs-0 mt-4 mb-4 mb-xs-0 mt-xs-0" dangerouslySetInnerHTML={{__html: column2.childMarkdownRemark.html}}></div>
        <div className="col-xs-4 col-lg-3" dangerouslySetInnerHTML={{__html: column3.childMarkdownRemark.html}}></div>
      </div>
    </div>
  )
}

export default CpbBody3Col

export const modelName = "ContentfulCpbBody3Col"

export const componentQuery = graphql`
  fragment CpbBody3ColFragment on ContentfulCpbBody3Col {
    __typename
    contentful_id
    theme
    column1 {
      childMarkdownRemark {
        html
      }
    }
    column2 {
      childMarkdownRemark {
        html
      }
    }
    column3 {
      childMarkdownRemark {
        html
      }
    }
  }
`
