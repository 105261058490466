import React from "react"
import { graphql } from "gatsby"

const CpbExternalVideo = ({theme, title, link}) => {
  theme = theme.toLowerCase().replace(" ", "-")
  return (
    <div className={`u__spacing u__vspacing theme-${theme}`}>
      <div className="u__container u__content cpb-external-video">
        <div className="cpb-external-video__wrapper">
          <iframe id="ytplayer" type="text/html" src={`${link}&embedded=true&modestbranding=1`} frameBorder="0" width="800" height="450" title={`Watch YouTube video ${title}`} allowFullScreen></iframe>
        </div>
      </div>
    </div>
  )
}

export default CpbExternalVideo

export const modelName = "ContentfulCpbExternalVideo"

export const componentQuery = graphql`
  fragment CpbExternalVideoFragment on ContentfulCpbExternalVideo {
    __typename
    contentful_id
    theme
    title
    link
  }
`
